.save__controls {
    position: relative;
    right: 10px;
    top: 10px; 

    z-index: 4;

}

.save__controls button {
    margin: 0px;
    float: right;

}