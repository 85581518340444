.container {
    display: flex;
    height: 80vh;
}

.tree {
    width: 250px;
    background-color: lightblue;
}

.properties {
    width: 250px;
    background-color: lightgreen;
}

.content {
    flex-grow: 1;
    background-color: lightgray;
}