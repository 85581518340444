.dashboard {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.dashboard__btn {
  padding: 10px;
  font-size: 18px;
  margin-top: 10px;
  border: none;
  color: white;
  background-color: black;
}

.dashboard div {
  margin-top: 7px;
}

.centerContent2 {
  margin: auto;
  text-align: center;
  align-content: center;
}
.helpImg {

  margin: 1em;
}