.process_preview_title {
    padding-left: 5px;
  }
  
  .process_preview {
    background-color: #f5f5f6;
    color: #34568b;
  
    min-width: '200px';
    padding: 4px;
    margin: 5px;
    cursor: pointer;
    border-radius: 0px 10px 0px 10px;
    border: 5;
    border-color: #34568b;
    box-shadow: 10px 4px 6px 12px rgb(0 0 0 / 15%), 0 2px 4px 12px rgb(0 0 0 / 8%);
  }
  
  .process_preview_active {
    color: #f5f5f6;
    background-color: #34568b;
  
    min-width: '200px';
    padding: 4px;
    margin: 5px;
    cursor: pointer;
    border-radius: 0px 10px 0px 10px;
    border: 5;
    border-color: #8b8434;
    box-shadow: 10px 4px 6px 12px rgb(0 0 0 / 15%), 0 2px 4px 12px rgb(0 0 0 / 8%);
  }
  
  .process_preview_header {
    background-color: #f5f5f6;
    color: #34568b;
    padding: 2px;
  }
  
  .process_preview_header_active {
    color: #f5f5f6;
    background-color: #b28a2b;
    padding: 1px;
  }
  
  .process_preview_header:hover {
    color: #f5f5f6;
    background-color: #34568b;
    padding: 1px;
  }
  
  .process_preview_header_title {
    padding-left: 5px;
  }
  
  .rightpanel2 {
    color: #f5f5f6;
    float: right;
    display: none;
    top: 2px;
    right: 2px;
    border-radius: 2px 20px 2px 20px;
  }
  
  .rightpanel3 {
    position: static;
    /* Safari */
  
    top: 100px;
    left: 100px;
  
    border: 2px solid #4CAF50;
  }
  
  
  .process_preview_header:hover .rightpanel2 {
    display: block;
  
  }
  
  .process_preview_header_title:hover .rightpanel2 {
    display: block;
  }
  
  .icon_component:hover .rightpanel3 {
    display: block;
  
  }
  
  .basket {
    position: relative;
    /* Safari */
    /* display: none; */
  
  }
  
  .wcomponent:hover .basket {
    display: block;
  
  }
  
  .wcomponent {
    min-width: '200px';
    min-height: '200px';
    background-color: aqua;
  }
  
  .active_window {
   
  }
  .window {
    opacity: 0.9;
    position: relative;
    margin: auto;
  }
  
  .active_window:hover {
  
   
  }
  
  
  
  .background {
    text-align: center;
    position: absolute;
    top: 200;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: -1;
    overflow: hidden;
    height: 100%;
  }
  
  
  .aaa:hover .bbb {
    display: block;
  
  }
  .bbb {
    display: none;
  
  }
  .aaa{
    min-width: 200px;
    min-height: 200px;
    background-color: #34568b;
  }